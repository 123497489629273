import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function heroAbout() {
  if (window.innerWidth > 1024) {
    const hero = document.querySelector('.not-found-section');
    const slideItems = document.querySelector('.not-found-section__slide-items');
    const slideItem = document.querySelectorAll('.not-found-section__slide-items .portfolio-card-wrapper');

    const numItems = slideItem.length;
    const itemHeight = slideItem[0].offsetHeight;
    const totalHeight = itemHeight * numItems;

    gsap.to(hero, {
      scrollTrigger: {
        trigger: hero,
        start: 'top 5%',
        end: `+=${totalHeight}`,
        pin: true,
        scrub: 2,
      },
    });

    gsap.fromTo(slideItems,
      { y: '100vh' },  // Початкове значення
      {
        y: `-${(totalHeight + 1500)}px`,  // Кінцеве значення
        scrollTrigger: {
          trigger: hero,
          start: 'top 5%',
          end: `+=${totalHeight} + 100vh`,
          scrub: 6,
        },
      }
    );
  }
}

window.addEventListener('load', () => {
  heroAbout();
  window.addEventListener('resize', heroAbout);
});
